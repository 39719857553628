
const SocialMediaIcons = () => {

  return (
    <div className="flex justify-center md:justify-start my-10 gap-7">
      <a
        className="hover:opacity-50 transition duration-500"
        href="https://www.linkedin.com/in/matthew-arofin"
        target="blank"
        rel="nonreferrer"
      >
        <img src="../assets/linkedin.png" alt="linkedin-link" />
      </a>

      <a
        className="hover:opacity-50 transition duration-500"
        href="https://www.twitter.com/0lamatt"
        target="blank"
        rel="nonreferrer"
      >
        <img src="../assets/twitter.png" alt="twitter-link" />
      </a>

      <a
        className="hover:opacity-50 transition duration-500"
        href="https://www.instagram.com/sage.host"
        target="blank"
        rel="nonreferrer"
      >
        <img src="../assets/instagram.png" alt="instagram-link" />
      </a>
    </div>
  )
};

export default SocialMediaIcons;